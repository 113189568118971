import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Articledetail from "../views/articledetail/Articledetail.vue";
import Articlelist from "../views/articlelist/Articlelist.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/articledetail/:cid",
    name: "Articledetail",
    component: Articledetail,
  },

  {
    path: "/articlelist/:keywords",
    name: "Articlelist",
    component: Articlelist,
    meta: {
      meta: {
        keepAlive: true,
      },
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
