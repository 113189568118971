<template>
  <div id="app" v-cloak>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="$route.path">
    </router-view>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getDetail } from "@/api/detail";
export default {
  data() {
    return {
      content: {},
    };
  },
  mounted() {
    this.checkSign();
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "checkSign", //getOrderInfo为自定义方法
  },
  methods: {
    async getContent(cid) {
      let params = {};
      params.contentId = cid;
      await getDetail(params).then((res) => {
        console.log(res);
        this.content = res;
      });
    },

    async checkSign() {
      let spstr = window.location.href.split("/");
      let articleNumber = spstr[spstr.length - 1];
      await this.getContent(articleNumber);
      console.log("in CHeckSIGN");
      console.log(this.content);
      let nowUrl = window.location.href;
      let rootUrl = window.location.href.split("#")[0];
      let title = this.content.data.data.title;
      let desc = this.content.data.data.intro;
      let imgUrl =
        "https://img.alicdn.com/imgextra/i4/O1CN01edOyRD27O2UDuVmGv_!!6000000007786-0-tps-500-500.jpg";
      console.log(title);
      this.$https
        .get(
          `https://media.iwshang.com/iwshang/api/product/wechat/getSign?url=${rootUrl}`
        )
        .then(function (res) {
          wx.config({
            debug: false, // 开启调试模式,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(function () {
            //需在用户可能点击分享按钮前就先调用
            // 分享给朋友
            wx.updateAppMessageShareData({
              title: title === null ? "天下网商" : title, // 分享标题
              desc:
                desc === null
                  ? "天下网商掌握权威的电商资讯和人脉，专注为新网商提供专业原创的电商..."
                  : desc, // 分享描述
              link: nowUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: imgUrl, // 分享图标
              success: function (res) {
                // 设置成功
                console.log("分享给朋友成功返回的信息为:", res);                
              },
            });
            // //分享到朋友圈”及“分享到QQ空间”
            wx.updateTimelineShareData({
              title: title === null ? "天下网商" : title, // 分享标题
              link: nowUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: imgUrl, // 分享图标
              success: function (res) {
                // 设置成功
                console.log("分享朋友圈成功返回的信息为:", res);
              },
            });
          });
          wx.error(function (res) {
            console.log(res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        });
    },
  },
};
</script>
<style lang="stylus">
[v-cloak] {
  display: none;
}

#app {
  font-family: 'PingFang SC', 'Microsoft Yahei', '宋体', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  margin: 0;
  padding: 0;
  height: 100%;
}

#app a {
  text-decoration: none;
}
</style>
