<template>
  <div class="home">
    <div class="header">
      <router-link to="/">
        <img
          src="https://img.alicdn.com/imgextra/i3/O1CN01s5DIXJ1s0wD0yQJSR_!!6000000005705-2-tps-2501-1401.png"
          alt=""
          style="width: 112px; height: 40px"
      /></router-link>
      <van-search
        shape="round"
        background="rgb(216, 12, 24)"
        @search="searchArticle"
        v-model="message"
      />
      <span class="search" @click="searchArticle">搜索</span>
    </div>
    <div class="container">
      <div class="list">
        <span>TAGS :</span><em>{{ tagname }}</em>
        <span
          >共<cite>{{ newscount }}</cite
          >条</span
        >
      </div>
      <section style="min-height: 460px">
        <div
          class="search-list-box"
          v-for="(item, index) in articlelist"
          :key="index"
        >
          <span class="search-h2">
            <router-link
              :to="'/articledetail/' + item.contentId"
              class="search-title"
              >{{ item.title }}</router-link
            >
          </span>
        </div>
      </section>
      <div class="loader" v-if="isLoader">
        <p @click="getMore" style="line-height: 30px">加载更多</p>
      </div>
    </div>
    <div style="width: 100%; height: 40px"></div>
    <div id="fixed-bottom">
      <div class="footer-content">
        <div class="footer-content-ul">
          <ul class="footer-content-ul-1">
            <li style="color: #91bef9">浙江天下网商网络传媒有限公司</li>
            <code>|</code>
            <li> 未经许可不得转载</li>
          </ul>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-content-ul">
          <ul class="footer-content-ul-1">
            <a href="https://beian.miit.gov.cn" target="_blank">
              <li>浙ICP备11003104号-2</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSearch, getSearchCount } from "@/api/search";
export default {
  name: "Articlelist",

  data() {
    return {
      message: "",
      tagname: "",
      newscount: 0,
      articlelist: [],
      page: 1,
      isLoader: true,
    };
  },
  watch: {
    $route() {
      this.$router.go(0);
    },
  },
  methods: {
    getArticlelist(keywords, i) {
      let params = {};
      params.keyword = keywords;
      params.page = i;
      params.pageSize = 11;
      getSearch(params).then((res) => {
        this.articlelist = this.articlelist.concat(res.data.data.result);
        if (this.articlelist.length < 11 && this.articlelist.length >= 0) {
          this.isLoader = false;
        } else {
          this.isLoader = true;
        }
      });
    },
    getTagname(keywords) {
      this.tagname = keywords;
    },
    getNewscount(keywords) {
      let params = {};
      params.keyword = keywords;
      getSearchCount(params).then((res) => {
        this.newscount = res.data.data.result;
      });
    },
    getMore() {
      this.page++;
      this.getArticlelist(this.$route.params.keywords, this.page);
    },
    searchArticle() {
      this.$router.push("/articlelist/" + this.message);
    },
    showLoader() {},
  },
  mounted() {
    this.getArticlelist(this.$route.params.keywords, this.page);
    this.getTagname(this.$route.params.keywords);
    this.getNewscount(this.$route.params.keywords);
    this.showLoader();
  },
};
</script>

<style scoped>
@import "./../../assets/common.css";

.header {
  background-color: rgb(216, 12, 24);
  width: 100%;
  height: 40px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
}

.search {
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  color: #fff;
  width: 80px;
  height: 30px;
}
.van-search__content--round {
  height: 30px;
}
.loader {
  background-color: #eee;
  height: 30px;
  margin-bottom: 20px;
}
.loader p {
  text-align: center;
}
.container {
  flex: 1;
  width: 100%;
}
#fixed-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
}
.list {
  background-color: #f6f6f6;
  height: 30px;
  padding: 10px 0 0 0;
  font-size: 14px;
  color: #4a4a4a;
}
.list span {
  display: block;
  float: left;
  padding-right: 5px;
  line-height: 20px;
  padding-left: 10px;
}
.list em {
  float: left;
  white-space: nowrap;
  display: block;
  background-color: #4aabf0;
  border-radius: 2px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  margin-right: 20px;
}
.list span cite {
  color: #4faced;
}
.search-title {
  color: #666666;
  font-size: 14px;
}
.search-h2 {
  display: block;
  padding: 10px 10px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search-list-box {
  border-bottom: 1px solid #eeeeef;
  width: 100%;
  height: 40px;
}
.loader {
  background-color: #eee;
  height: 30px;
  margin-bottom: 20px;
}
.loader p {
  text-align: center;
}
</style>
