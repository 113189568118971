<template>
  <div class="home">
    <div class="header">
      <router-link to="/"
        ><img
          src="https://img.alicdn.com/imgextra/i3/O1CN01s5DIXJ1s0wD0yQJSR_!!6000000005705-2-tps-2501-1401.png"
          alt=""
          style="width:112px;height:40px"
      /></router-link>
      <van-search
        v-model="message"
        shape="round"
        background="rgb(216, 12, 24)"
        @search="searchArticle"
      />
      <span class="search" @click="searchArticle">搜索</span>
    </div>

    <home-section></home-section>

    <div style="width:100%;height:40px"></div>

    <div id="fixed-bottom">
      <div class="footer-content">
        <div class="footer-content-ul">
          <ul class="footer-content-ul-1">
            <li style="color:#91bef9">浙江天下网商网络传媒有限公司</li>
            <code>|</code>
            <li> 未经许可不得转载</li>
          </ul>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-content-ul">
          <ul class="footer-content-ul-1">
            <a href="https://beian.miit.gov.cn" target="_blank">
              <li>浙ICP备11003104号-2</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSection from "./components/Section";

export default {
  name: "Articledetail",
  components: {
    HomeSection,
  },
  data() {
    return {
      message: "",
    };
  },

  methods: {
    searchArticle() {
      this.$router.push("/articlelist/" + this.message);
    },
  },
};
</script>

<style scoped>
.fixed-top {
  position: sticky;
  top: 0;
  z-index: 999;
}
.header {
  background-color: rgb(216, 12, 24);
  width: 100%;
  height: 40px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
}

.search {
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  color: #fff;
  width: 80px;
  height: 30px;
}
.van-search__content--round {
  height: 30px;
}
#fixed-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
}
</style>
