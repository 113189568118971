import request from "@/utils/request";

// 详情
export function getDetail(params) {
  return request({
    url: "/api/product/content/detail",
    method: "get",
    params,
  });
}

//相关文章推荐
export function getRecommend(params) {
  return request({
    url: "/api/product/content/correlationRecommend",
    method: "get",
    params,
  });
}
