<template>
  <div>
    <div class="content-header">
      <h2>{{ content.title }}</h2>
      <p>{{ content.created }}</p>
      <div></div>
    </div>

    <div class="content-body" ecsape="false">
      <div style="padding: 0 10px">
        <span v-html="content.content" class="con-img"></span>
      </div>
    </div>

    <div class="about-detail">
      <h4 style="margin: 0; padding-left: 5px">相关文章推荐</h4>

      <div
        class="tag-list-box"
        v-for="(item, index) in correlationlist"
        :key="index"
      >
        <router-link :to="'/articledetail/' + item.contentId">
          <img :src="item.thumb" class="tag-list-img1"
        /></router-link>

        <h3>
          <router-link
            :to="'/articledetail/' + item.contentId"
            class="tag-list-title"
            >{{ item.title }}</router-link
          >
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail, getRecommend } from "@/api/detail";
export default {
  name: "Section",
  data() {
    return {
      content: {},
      contentid: 0,
      url: "",
      correlationlist: [],
      cid: 0,
    };
  },

  methods: {
    getContent(cid) {
      let params = {};
      params.contentId = cid;
      getDetail(params).then((res) => {
        this.content = res.data.data;
      });
    },
    getCorrelation(cid) {
      let params = {};
      params.contentId = cid;
      getRecommend(params).then((res) => {
        this.correlationlist = res.data.data.result;
      });
    },
  },
  mounted() {
    this.getContent(this.$route.params.cid);
    this.contentid = this.$route.params.cid;
    this.url = window.location.href;
    this.getCorrelation(this.$route.params.cid);
  },
};
</script>

<style>
@import "../../../assets/common.css";

.content-header h2 {
  font-size: 20px;
  margin: 10px 10px 5px;
  color: #010101;
}
.content-header p {
  font-size: 12px;
  color: #898989;
  margin: 0 10px;
}

.content-body {
  background-color: #f6f6f6;
  padding: 30px 0;
  min-height: 900px;
}
.content-body p {
  font-size: 16px;
  color: #333;
}

.con-img img {
  width: 100% !important;
  height: auto !important;
  max-width: 100% !important;
  display: block !important;
}
.about-detail {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;
}
.about-detail p {
  margin: 15px;
  border-bottom: 1px solid #333;
}
.about-link {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}
</style>
