import request from "@/utils/request";

// 栏目列表
export function getBanner(params) {
  return request({
    url: "/api/product/banner/list",
    method: "get",
    params,
  });
}
