<template>
  <div>
    <div class="header">
      <router-link to="/">
        <img
          src="https://img.alicdn.com/imgextra/i3/O1CN01s5DIXJ1s0wD0yQJSR_!!6000000005705-2-tps-2501-1401.png"
          alt=""
          style="width: 112px; height: 40px"
      /></router-link>

      <van-search
        shape="round"
        background="rgb(216, 12, 24)"
        @search="searchArticle"
        v-model="message"
      /><span class="search" @click="searchArticle">搜索</span>
    </div>

    <div class="banner">
      <el-carousel height="180px">
        <el-carousel-item v-for="(item, index) in topswiperlist" :key="index">
          <a :href="item.bannerLink" target="_blank">
            <div class="swiper-title">
              <span>
                {{ item.bannerName }}
              </span>
            </div>
            <img :src="item.image" style="width: 100%; height: 100%"
          /></a>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div style="min-height: 460px">
      <div
        class="tag-list-box"
        v-for="(item, index) in articlelist"
        :key="index"
      >
        <router-link :to="'/articledetail/' + item.contentId">
          <img :src="item.thumb" class="tag-list-img1"
        /></router-link>

        <h3>
          <router-link
            :to="'/articledetail/' + item.contentId"
            class="tag-list-title"
            >{{ item.title }}</router-link
          >
        </h3>
      </div>
    </div>
    <div class="loader">
      <p @click="getMore" style="line-height: 30px">加载更多</p>
    </div>

    <div style="width: 100%; height: 40px"></div>
    <div id="fixed-bottom">
      <div class="footer-content">
        <div class="footer-content-ul">
          <ul class="footer-content-ul-1">
            <li style="color: #91bef9">浙江天下网商网络传媒有限公司</li>
            <code>|</code>
            <li> 未经许可不得转载</li>
          </ul>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-content-ul">
          <ul class="footer-content-ul-1">
            <a href="https://beian.miit.gov.cn" target="_blank">
              <li>浙ICP备11003104号-2</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeList } from "@/api/home";
import { getBanner } from "@/api/banner";

export default {
  data() {
    return {
      articlelist: [],
      page: 1,
      message: "",
      topswiperlist: [],
    };
  },
  methods: {
    getArticlelist(i) {
      let params = {};
      params.page = i;
      params.pageSize = 10;
      getHomeList(params).then((res) => {
        this.articlelist = this.articlelist.concat(res.data.data.result);
      });
    },

    getMore() {
      this.page++;
      this.getArticlelist(this.page);
    },
    searchArticle() {
      this.$router.push("/articlelist/" + this.message);
    },
  },

  mounted() {
    this.getArticlelist(this.page);
    let params = {};
    params.columnId = 11;
    params.page = 1;
    params.pageSize = 4;
    getBanner(params).then((res) => {
      this.topswiperlist = res.data.data.result;
    });
  },
};
</script>

<style>
@import "./../../assets/common.css";

.header {
  background-color: rgb(216, 12, 24);
  width: 100%;
  height: 40px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
}

.loader {
  background-color: #eee;
  height: 30px;
  margin-bottom: 20px;
}
.loader p {
  text-align: center;
}
.swiper-title {
  line-height: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 14px;
}
.search {
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  color: #fff;
  width: 80px;
  height: 30px;
}

.van-search__content--round {
  height: 30px;
}
#fixed-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
}
</style>
